//Dropdown menu
var burgerBtn = document.querySelector('.o-menu-toggle');
var burgerBtnClose = document.querySelector('.close');
var nav = document.querySelector('.c-nav');
var body = document.querySelector('body');

burgerBtn.addEventListener('click', function() {
    nav.style.transform = 'translateX(0)';
    nav.style.overflowY = 'auto';
    body.classList.add('overlay-open');
    body.style.overflowY = 'hidden';
});

burgerBtnClose.addEventListener('click', function() {
    nav.style.transform = 'translateX(-345px)';
    body.classList.remove('overlay-open');
    body.style.overflowY = 'auto';
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});